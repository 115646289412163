export const environment = {
  apiUrl: 'https://api.base.28one-uat.com',
  ENV: 'uat',
  sentry: {"enable":false,"enableReplayIntegration":false,"enableBrowserTracingIntegration":false},
  dataName: 'superdry',
  clientName: 'superdry',
  enableReset: false,
  production: false,
  uiClienId: 'a2cb7ab5-5b7a-4d43-8dcb-c364369697a8',
  redirectUrl: 'https://superdry.28one-uat.com',
  loginUrl: 'https://login.28one-uat.com/ef33ef60-9368-48e5-b3b0-e69b3fcc51be/oauth2/v2.0/authorize?p=B2C_1A_SIGNUP_SIGNIN&client_id=5994d158-090c-47a2-bb37-d6a91b8e4423&nonce=defaultNonce&scope=openid&response_type=id_token&prompt=login',
  overrideSizeChart: 'false',
  showPendingDecision: 'false',
  navBar: {
    leftImage: 'assets/img/superdry-x2.png',
    leftImageHeight: 30,
    menuSetting: '[{"name":"reports","default":true,"page-name":"ReportSectionf71766f04794c71c8443","report-id":"15ea1137-b3cd-4f39-9a18-bef4d2a65f6e"},{"name":"dashboards","default":false,"page-name":"ReportSection791bcdeec022f2c08cd5","report-id":"15ea1137-b3cd-4f39-9a18-bef4d2a65f6e"},{"name":"monthly report","default":false,"page-name":"ReportSectionb4d5469260d3ed672a50","report-id":"15ea1137-b3cd-4f39-9a18-bef4d2a65f6e"},{"name":"tech team report","default":false,"page-name":"ReportSectionbf94786c74b072ede91c","report-id":"fa521092-a5cc-4e5b-91d3-0e3323b97b42"},{"name":"logistics report","default":false,"page-name":"ReportSectionda58d26f742db7fad164","report-id":"5dde3be6-3a42-44e8-88f8-6de4e47a6569"},{"name":"inspection","default":false}]',
    roleFilter: '{}',
    showAdmin: true,
    asDropdown: false,
  },
  sideBar: '{"inspection-plan-title":"AQL Inspection Plan","show-skip-inspections":false,"inspections-icon":"t-shirt.svg","show-po-selection":false,"show-packaging":true,"show-downloads":true}',
  import: '{"select-group":true,"title":"UPLOAD SUPPLIER BOOKING DETAILS","select-centre":true,"select-inspection-level":false,"select-inspection-type":false}',
  uploadSizeChart: '{"add-title":"ADD STYLE","need-upload":true,"title":"UPLOAD SIZE CHART","delete-title":"DELETE STYLE CODE","product-code-title":"Style Code"}',
  clientInspection: '{"table":[{"name":"Insp. Date","case":"name","key":"inspection_date"},{"name":"Loc.","type":"number","case":"normal","key":"UDC_id"},{"name":"Insp.","type":"number","case":"normal","key":"inspection_number"},{"name":"Supplier Name","case":"name","key":"supplier_name"},{"name":"Season","case":"normal","key":"season_code"},{"name":"PO Numbers","case":"normal","key":"client_pos"},{"name":"Style","case":"normal","key":"style_code"},{"name":"Description","case":"name","key":"style_name"},{"name":"Colour","case":"name","key":"colour"},{"name":"Result","case":"result","key":"result"},{"name":"Full Report","type":"pdf","case":"file"},{"name":"Summary Report","type":"pdf","case":"summary_file"},{"name":"Version History","case":"report_versions"}],"colour-filter":"Colour","po-number":"PO Number","division-filter":"","date-range-minus":1,"result-filter":"Result","show-client-decision-filter":false,"consol-filter":"Consol. Centre","department-list":""}',
  inspectorCommentLength: '400',
  selectInspection: '{"order-number":true,"select-centre":true,"show-open-order-filter":false,"date-range-plus":0,"table":[{"name":"Arrived","case":"name","key":"arrived_consol_date"},{"name":"Size Chart","type":"number","case":"normal","key":"size_chart_YN"},{"name":"Insp.","type":"number","case":"normal","key":"inspection_number"},{"name":"Style","case":"normal","key":"style_code"},{"name":"TOT. QTY.","type":"number","case":"normal","key":"order_quantity"},{"name":"Comp.","case":"check","key":"select_for_compliance"},{"name":"AQL","case":"check","key":"select_for_AQL"},{"name":"Inspection Date","case":"select_date","key":"inspection_date"},{"name":"Supplier Name","case":"name","key":"supplier_name"},{"name":"PO Numbers","case":"normal","key":"client_pos","empty":true},{"name":"Action","case":"insp-action"}],"date-title":"Arrived Date","date-range-minus":1}',
  aqlPlan: '{"date-range-plus":0,"show-supplier-filter":true,"table":[{"name":"AQL","show":true,"case":"select_unselect","key":"qcdata_select_for_AQL"},{"name":"Insp. Date","show":true,"case":"name","key":"inspection_date"},{"name":"Loc.","show":true,"type":"number","case":"normal","key":"UDC_id"},{"name":"Insp.","show":true,"type":"number","case":"normal","key":"inspection_number"},{"name":"Supplier Name","show":true,"case":"name","key":"supplier_name"},{"name":"Season","show":true,"case":"normal","key":"season_code"},{"name":"PO Numbers","show":true,"case":"normal","key":"client_pos"},{"name":"STYLE","show":true,"case":"normal","key":"style_code"},{"name":"Description","show":true,"case":"name","key":"style_name"},{"name":"Colour","show":true,"case":"name","key":"colour"},{"name":"PO QTY.","show":true,"type":"number","case":"normal","key":"order_quantity"},{"name":"Level","show":true,"type":"number","case":"level","key":"sample_level"},{"name":"INSP. Qty.","show":true,"type":"number","case":"normal","key":"inspection_quantity"},{"name":"SUB.","show":false,"type":"number","case":"normal","key":"inspection_number"},{"name":"AQL Maj<","show":true,"type":"number","case":"normal","key":"allowed_defects_maj"},{"name":"AQL Min<","show":true,"type":"number","case":"normal","key":"allowed_defects_min"},{"name":"Tot. Defects","show":true,"type":"number","case":"normal","key":"count_defects"},{"name":"Current Status","show":true,"case":"normal","key":"status_EN"},{"name":"Result","show":true,"case":"result","key":"result"},{"name":"Inspector Name","show":true,"case":"normal","key":"Inspector"},{"name":"Customer","show":false,"case":"normal","key":"customer_name"}],"select-centre":true,"show-color":true,"title":"AQL Inspection Plan","show-po-number":true,"date-range-minus":1,"show-petek-number":false,"style-code":"Style Code","show-inspection-type":false,"status-filter":"Status"}',
  aqlReport: '{"show-close-btn":false,"show-max-allowed":true,"info-fields":[{"field":"style_code","title":"Style Code"},{"field":"style_name","title":"Style Name"},{"field":"colour","title":"Colour"},{"field":"supplier_name","title":"Supplier"},{"field":"PO_Nos","title":"PO Number"},{"field":"Order_Size","title":"Order Size"},{"field":"inspection_number","title":"Submission"},{"field":"sample_level","title":"Inspection Level"},{"field":"inspection_quantity","title":"Sample Qty."}],"show-result":true,"show-summary-inspection-report":true,"title":"AQL Inspection Report","major":"Major","critical":"Critical","show-centre":true,"show-inspection-date":false,"show-customer-name":false,"minor":"Minor","show-inspection-type":false,"show-inspection-id":true}',
  mainPage: {"redirect-urls":[]},
};